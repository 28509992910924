<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">杭州里德荣获“两轮车锂电池保护板技术领导品牌”称号</div>
          <div class="notice-time">
            <div>发布时间： 2020/09/30 10:09:20</div>
            <div>浏览： 65次</div>
          </div>
          <p>
            2020年9月25日，以“新思维 新模式 新技术 新生态 ”为主题的第二届中国（锂电）电动自行车产业生态大会暨轻型动力锂电技术高峰论坛在无锡隆重举行。会议期间杭州里德荣获“2020中国两轮车锂电池保护板技术领导品牌”称号。
          </p>
          <div class="notice-img-1">
            <img src="./5.png" srcset="./5.png,./5@2x.png 2x" alt="">
            <img src="./6.png" srcset="./6.png,./6@2x.png 2x" alt="">
          </div>
          <p>
            会上，里德通信研发经理吴洋洋发表了题为《电动车辆锂电池管理系统解决方案》的演讲，从两三轮电动车大市场的趋势分析，到未来发展方向和产品介绍，及物联网生态和BMS产品介绍三个方面展开。吴经理分析现有电动车市场，认为市面上大部分的电动车都属于超标车，随着新国标的颁布，超标的电动轻便摩托车都将强制退市，未来两年的市场替换量潜能巨大，电动车市场的市值可以达到万亿级，电动自行车锂电池市场可以达到3000亿级，BMS市场大约有300亿左右的规模。同时，随着这两年新兴的互联网企业的崛起，2B端换电以及外卖市场对电动轻便车的需求量也在不断上升。吴经理提出，针对当前市场发展趋势，在提升质量和加大研发投入的同时，我们更需要朝着“行业标准化、生产规模化、需求市场化、产品物联化”的方向发展。在提升产品品质的同时，进一步推行智能化，便捷化，信息化的服务升级，紧跟行业动态，助力行业发展。
          </p>
          <p style="margin-top: 32px">
            里德，秉承“格物 良知”的核心价值观，始终坚持以客户为中心，团结、拼搏、求实、进取的奋进思想，将进一步为锂电行业增添新动力，贡献新力量！
          </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice1">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice3">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;

  img {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-next,
.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
